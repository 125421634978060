/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Layout, SEO, QuoteBox, ContentfulRichText } from "../components"
import GetCashOfferForm from "../forms/get-cash-offer"

const SellHouse =  ({ data }) => {
  const {
    title,
    headingTitle,
    headingSubtitle,
    mainContentDescription,
    mainContentImage,
    quoteBox,
    additionalContent,
  } = data.contentfulSellAHouseTemplate

  return (
    <Layout>
      <SEO title={title} />
      <Container sx={{ px: 3, py: [4, 5, 100] }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", null, "2fr 1fr"],
            gridGap: [4, 5],
            mb: [4, 5, 100],
          }}
        >
          <div sx={{ textAlign: "center" }}>
            <Heading as="h1" sx={{ fontSize: 5, mb: 3 }}>
              {headingTitle}
            </Heading>
            <div sx={{ fontSize: 3, mb: [4, 5] }}>{headingSubtitle}</div>
            <Image fluid={mainContentImage.fluid} />
            <ContentfulRichText
              sx={{ textAlign: "left" }}
              content={mainContentDescription}
            />
          </div>
          <div>
            <GetCashOfferForm />
          </div>
        </div>
        <QuoteBox richText={quoteBox} />
        <ContentfulRichText
          content={additionalContent}
          sx={{
            textAlign: "center",
            maxWidth: 832,
            ml: "auto",
            mr: "auto",
            mt: [4, 5],
          }}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulSellAHouseTemplate(title: { eq: "Sell A House" }) {
      id
      title
      headingTitle
      headingSubtitle
      mainContentImage {
        ...CustomContentfulFluid
      }
      mainContentDescription {
        raw
      }
      quoteBox {
        raw
      }
      additionalContent {
        raw
      }
    }
  }
`

export default SellHouse
/** @jsx jsx */
import { jsx, Box, Label, Input, Button, Heading, Flex } from "theme-ui"

export default ({ ...props }) => (
  <form
    sx={{ bg: "gray3", p: [3, 4] }}
    {...props}
    name="contact"
    method="POST"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    action="/thanks/"
  >
    <input type="hidden" name="form-name" value="contact" />
    <p css="display: none">
      <label>
        Don’t fill this out if you're human: <input name="bot-field" />
      </label>
    </p>
    <Heading as="h3" sx={{ fontSize: [4, 5] }}>
      Get A Cash Offer
    </Heading>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="name">
        Name *
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="Name"
        name="name"
        type="text"
        id="name"
        required
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="email">
        Email *
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="Email"
        name="email"
        type="email"
        id="email"
        required
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="phone">
        Phone *
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="Phone"
        name="phone"
        type="tel"
        id="phone"
        required
      />
    </Box>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="property-address">
        Property Address *
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="Property Address"
        name="property-address"
        type="text"
        id="property-address"
        required
      />
    </Box>
    <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
      <Box sx={{ width: ["100%", "48%"], my: [3, 0] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="property-city">
          Property City *
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Property City"
          name="property-city"
          type="text"
          id="property-city"
          required
        />
      </Box>
      <Box sx={{ width: ["100%", "48%"] }}>
        <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="property-state">
          Property State
        </Label>
        <Input
          sx={{
            borderColor: "gray5",
            bg: "background",
            px: 3,
            py: 2,
          }}
          placeholder="Property State"
          name="property-state"
          type="text"
          id="property-state"
        />
      </Box>
    </Flex>
    <Box my={3}>
      <Label mb={2} color="gray4" sx={{ fontWeight: "heading" }} htmlFor="property-zipcode">
        Property Zip Code *
      </Label>
      <Input
        sx={{
          borderColor: "gray5",
          bg: "background",
          px: 3,
          py: 2,
        }}
        placeholder="Property Zip Code"
        name="property-zipcode"
        type="text"
        id="property-zipcode"
        required
      />
    </Box>
    <Button
      sx={{
        px: 3,
        py: 2,
        bg: "secondary",
        border: theme => `1px solid ${theme.colors.secondary}`,
        width: "100%",
        fontWeight: "heading",
        cursor: "pointer",
      }}
      type="submit"
    >
      Get a cash offer
    </Button>
  </form>
)
